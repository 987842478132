// App imports
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Container } from "@material-ui/core";
import CssBaseline from '@material-ui/core/CssBaseline';

// import css
import 'style/App.css';
import 'style/imported-css/bootstrap.css';
import 'style/imported-css/crunch.css';
import 'style/custom-css/image-custom.css';
import 'style/imported-css/microsite.css';
import 'style/imported-css/microsite-new.css';
import 'style/imported-css/microsite-cmmc.css';
import 'style/imported-css/microsite-imp.css';
import 'style/imported-css/microsite-privacy-services.css';
import 'style/imported-css/microsite-rm.css';
import 'style/imported-css/main.css';

import theme from 'style/theme';

// import components
import Footer from './components/layout/footer'
import NavBar from "components/layout/navBar";
import MainPage from "components/layout/pages/mainPage";
import FederalSectorPage from "components/layout/pages/federalSectorPage";
import BlogPostFederalFinancial from "components/layout/pages/blog/blogPostFederalFinancial";
import CommercialSectorPage from "components/layout/pages/commercialSectorPage";
import MarketsPage from "components/layout/pages/marketsPage";
import PastPerformancePage from "components/layout/pages/pastPerformancePage";
import PPInfoSecurity from "components/layout/pages/pastPerformance/ppInfoSecurity";
import PPBusinessContinuityPlanning from "components/layout/pages/pastPerformance/ppBusinessContinuityPlanning";
import PPGrowingOperations from "components/layout/pages/pastPerformance/ppGrowingOperations";
import PPEnsuringPerformance from "components/layout/pages/pastPerformance/ppEnsuringPerformance";
import PPRevenueImprovements from "components/layout/pages/pastPerformance/ppRevenueImprovements";
import PPSystemImprovements from "components/layout/pages/pastPerformance/ppSystemImprovements";
import PPHealthcareManagement from "components/layout/pages/pastPerformance/ppHealthcareManagement";
import PPTechnologyBusiness from "components/layout/pages/pastPerformance/ppTechnologyBusiness";
import PPManageKnowledge from "components/layout/pages/pastPerformance/ppManageKnowledge";
import CareersPage from "components/layout/pages/careersPage";
import NmuPartnershipPage from "components/layout/pages/nmuPartnershipPage";
import MarquetteInternships from "components/layout/pages/marquetteInternships";
import MarquetteCareers from "components/layout/pages/marquetteCareers";
import CorporateInformationPage from "components/layout/pages/corporateInformationPage";
import DevOpsMicrosite from "components/microsites/devops/DevOpsMicrosite";
import BankingFinancialMicrosite from "components/microsites/bankingFinancial/BankingFinancialMicrosite";
import Analytics from "components/microsites/bankingFinancial/analytics.component"
import CMMCMicrosite from "components/microsites/cmmc/CMMCMicrosite";
import CMMCBlogPost from "components/microsites/cmmc/cmmcBlogPost.component"
import CybersecurityMicrosite from "components/microsites/cybersecurity/CybersecurityMicrosite";
import CriticalIndustries from "components/microsites/cybersecurity/CriticalIndustriesPage";
import SecurityPortal from "components/microsites/cybersecurity/SecurityPortalPage"
import IncidentManagementMicrosite from "components/microsites/incidentManagement/IncidentManagementMicrosite";
import IntegrationMicrosite from "components/microsites/integration/IntegrationMicrosite";
import MergerAcquistionMicrosite from "components/microsites/mergerAcquistion/MergerAcquistionMicrosite";
import OrchestrationPlatformMicrosite from "components/microsites/orchestrationPlatform/OrchestrationPlatformMicrosite";
import PrivacyServicesMicrosite from "components/microsites/privacyServices/PrivacyServicesMicrosite";
import RiskManagementMicrosite from "components/microsites/riskManagement/RiskManagementMicrosite";
import SMSMicrosite from "components/microsites/sms/SMSMicrosite";
import VendorManagementMicrosite from "components/microsites/vendorManagement/VendorManagementMicrosite";
import CookieNoticePage from "components/layout/pages/cookieNotice";
import PrivacyStatementPage from "components/layout/pages/privacyStatement";
import TermsOfUsePage from "components/layout/pages/termsOfUse";
import AboutPage from "components/layout/pages/aboutPage";
import ContactPage from "components/layout/pages/contactPage";
import ServicesCapabilitiesPage from "components/layout/pages/servicesCapabilitiesPage";
import Star2Microsite from "components/microsites/stars2/stars2Microsite";
import Star2Services from "components/microsites/stars2/stars2Services";
import Star2Team from "components/microsites/stars2/stars2Team";
import Star2Info from "components/microsites/stars2/stars2Info";
import Star2AboutStar2 from "components/microsites/stars2/stars2AboutStar2";
import PressReleasePage from "components/layout/PressReleasePage";
import GRCMicrosite from "components/microsites/grc/GRCMicrosite";
import EFMicrositePage from "components/microsites/educationFoundation/EFMicrosite";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { useMemo } from "react";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: false,
    marginLeft: 'unset',
    marginRight: 'unset',
    padding: 0,
    flex: 1,
  },
}));
const microsites=[
  '/privacy-services',
  '/cmmc',
  '/grc',
  '/risk-management',
  '/sms',
  '/devops',
  '/incident-management',
  '/education',
  '/stars2',
]
const App = () => {
  const classes = useStyles();
  var path = window.location.pathname;

  const isMicrosite = useMemo(() => {
    if (path.length > 1 || path !== "/") {
      // Checks for outdated microsite url requests
      const cleanedPath = path.replace(/^\/capabilities/, '')
      return microsites.find((microsite) => cleanedPath.includes(microsite))
    } else {
      return false
    }
  }, [path])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {isMicrosite ? "" :<NavBar />}
      <Container
        className={classes.root}
        maxWidth={false}
      >
        <Router>
          <CssBaseline />
          <Switch>
            <Route exact path={["/"]}>
              <MainPage/>
            </Route>
            <Route exact path={["/cookie-notice"]}>
              <CookieNoticePage/>
            </Route>
            <Route exact path={["/privacy-statement"]}>
              <PrivacyStatementPage/>
            </Route>
            <Route exact path={["/terms-of-use"]}>
              <TermsOfUsePage/>
            </Route>
            <Route exact path={["/markets"]}>
              <MarketsPage />
            </Route>
            <Route exact path={["/about"]}>
              <AboutPage />
            </Route>
            <Route exact path={["/press-releases.php", "/press-releases"]}>
              <PressReleasePage />
            </Route>
            <Route exact path={["/about-ncg/contact", "/about-ncg/contact.php"]}>
              <ContactPage />
            </Route>
            <Route exact path={["/capabilities"]}>
              <ServicesCapabilitiesPage />
            </Route>
            <Route exact path={["/about-ncg/career-opportunities/careers", "/about-ncg/career-opportunities/careers.php"]}>
              <CareersPage />
            </Route>
            <Route exact path={["/past-performance"]}>
              <PastPerformancePage />
            </Route>
            <Route exact path={["/performance/info-security", "/performance/info-security.php"]}>
              <PPInfoSecurity />
            </Route>
            <Route exact path={["/performance/business-continuity", "/performance/business-continuity.php"]}>
              <PPBusinessContinuityPlanning />
            </Route>
            <Route exact path={["/performance/growing-operations", "/performance/growing-operations.php"]}>
              <PPGrowingOperations />
            </Route>
            <Route exact path={["/performance/ensuring-performance", "/performance/ensuring-performance.php"]}>
              <PPEnsuringPerformance />
            </Route>
            <Route exact path={["/performance/healthcare-management", "/performance/healthcare-management.php"]}>
              <PPHealthcareManagement />
            </Route>
            <Route exact path={["/performance/manage-knowledge", "/performance/manage-knowledge.php"]}>
              <PPManageKnowledge />
            </Route>
            <Route exact path={["/performance/revenue-improvements", "/performance/revenue-improvements.php"]}>
              <PPRevenueImprovements />
            </Route>
            <Route exact path={["/performance/system-improvements", "/performance/system-improvements.php"]}>
              <PPSystemImprovements />
            </Route>
            <Route exact path={["/performance/technology-business"]}>
              <PPTechnologyBusiness/>
            </Route>
            <Route exact path={["/about-ncg/corporate-background", "/about-ncg/corporate-background.php"]}>
              <CorporateInformationPage />
            </Route>
            <Route exact path={["/nmu-partnership"]}>
              <NmuPartnershipPage />
            </Route>
            <Route exact path={["/markets/federal-services"]}>
              <FederalSectorPage />
            </Route>
            <Route exact path={["/blog/blog-post-federal-financial"]}>
              <BlogPostFederalFinancial />
            </Route>
            <Route exact path={["/markets/commercial-services"]}>
              <CommercialSectorPage />
            </Route>

            <Route exact path={["/banking-financial"]}>
              <BankingFinancialMicrosite/>
            </Route>
            <Route exact path={["/capabilities/banking-financial"]}>
              <Redirect to="/banking-financial" />
            </Route>
            <Route exact path={["/banking-financial/analytics"]}>
              <Analytics/>
            </Route>
            <Route exact path={["/capabilities/banking-financial/analytics"]}>
              <Redirect to="/banking-financial/analytics" />
            </Route>

            <Route exact path={["/cmmc"]}>
              <CMMCMicrosite/>
            </Route>
            <Route exact path={["/capabilities/cmmc"]}>
              <Redirect to="/cmmc" />
            </Route>
            <Route exact path={["/blog/blog-post-cmmc", "/blog/blog-post-cmmc.php"]}>
              <CMMCBlogPost/>
            </Route>

            <Route exact path={["/cybersecurity"]}>
              <CybersecurityMicrosite/>
            </Route>
            <Route exact path={["/capabilities/cybersecurity"]}>
              <Redirect to="/cybersecurity" />
            </Route>
            <Route exact path={["/cybersecurity/critical-industries"]}>
              <CriticalIndustries/>
            </Route>
            <Route exact path={["/capabilities/cybersecurity/critical-industries"]}>
              <Redirect to="/cybersecurity/critical-industries" />
            </Route>

            <Route exact path={["/orchestration-platform/security-portal"]}>
              <SecurityPortal/>
            </Route>
            <Route exact path={["/capabilities/orchestration-platform/security-portal"]}>
              <Redirect to="/orchestration-platform/security-portal" />
            </Route>

            <Route exact path={["/devops"]}>
              <DevOpsMicrosite/>
            </Route>
            <Route exact path={["/capabilities/devops"]}>
              <Redirect to="/devops" />
            </Route>

            <Route exact path={["/incident-management"]}>
              <IncidentManagementMicrosite/>
            </Route>
            <Route exact path={["/capabilities/incident-management"]}>
              <Redirect to="/incident-management" />
            </Route>

            <Route exact path={["/integration"]}>
              <IntegrationMicrosite/>
            </Route>
            <Route exact path={["/capabilities/integration"]}>
              <Redirect to="/integration" />
            </Route>

            <Route exact path={["/merger-acquisition"]}>
              <MergerAcquistionMicrosite/>
            </Route>
            <Route exact path={["/capabilities/merger-acquisition"]}>
              <Redirect to="/merger-acquisition" />
            </Route>

            <Route exact path={["/orchestration-platform"]}>
              <OrchestrationPlatformMicrosite/>
            </Route>
            <Route exact path={["/capabilities/orchestration-platform"]}>
              <Redirect to="/orchestration-platform" />
            </Route>

            <Route exact path={["/privacy-services"]}>
              <PrivacyServicesMicrosite/>
            </Route>
            <Route exact path={["/capabilities/privacy-services"]}>
              <Redirect to="/privacy-services" />
            </Route>

            <Route exact path={["/risk-management"]}>
              <RiskManagementMicrosite/>
            </Route>
            <Route exact path={["/capabilities/risk-management"]}>
              <Redirect to="/risk-management" />
            </Route>

            <Route exact path={["/sms"]}>
              <SMSMicrosite/>
            </Route>
            <Route exact path={["/capabilities/sms"]}>
              <Redirect to="/sms" />
            </Route>

            <Route exact path={["/grc"]}>
              <GRCMicrosite />
            </Route>

            <Route exact path={["/vendor-management"]}>
              <VendorManagementMicrosite/>
            </Route>
            <Route exact path={["/capabilities/vendor-management"]}>
              <Redirect to="/vendor-management" />
            </Route>

            <Route exact path={["/education"]}>
              <EFMicrositePage />
            </Route>

            <Route exact path={["/stars2"]}>
              <Star2Microsite/>
            </Route>
            <Route exact path={["/stars2/services"]}>
              <Star2Services/>
            </Route>
            <Route exact path={["/stars2/ncg_team"]}>
              <Star2Team/>
            </Route>
            <Route exact path={["/stars2/about_ncg"]}>
              <Star2Info/>
            </Route>
            <Route exact path={["/stars2/about_stars2"]}>
              <Star2AboutStar2/>
            </Route>
            <Route exact path={["/about-ncg/career-opportunities/mq-internships.php", "/about-ncg/career-opportunities/mq-internships"]}>
              <MarquetteInternships/>
            </Route>
            <Route exact path={["/about-ncg/career-opportunities/mq-careers.php", "/about-ncg/career-opportunities/mq-careers"]}>
              <MarquetteCareers/>
            </Route>

            
          </Switch>
        </Router>

      </Container>
      {path.includes('stars2') ? <></> : <Footer />}
    </ThemeProvider>
  );
}

export default App;
