import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Container } from "@material-ui/core";
import React, { useEffect, useState } from "react";
// import React, { useState } from "react";
import LinkDefault from "components/layout/linkDefault.component";
import PageWrapper from "components/layout/pageWrapper.component";
import Banner from "components/layout/banner.component";
import BackToTopCustom from "components/layout/BackToTopCustom";
import classNames from "classnames";
import Spotlight from "components/layout/spotlight.js";
import variables from "../../../style/variables.module.scss";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
// IMAGES
import capabilitiesBanner from "../../../images/images/banners/process.jpg";
import programLogo from "../../../images/images/capabilities/programLogo.png";
import cybersecurityLogo from "../../../images/images/capabilities/cybersecurityLogo.png";
import mergersLogo from "../../../images/images/capabilities/mergerLogo.png";
import complianceLogo from "../../../images/images/capabilities/complianceLogo.png";
import riskLogo from "../../../images/images/capabilities/riskLogo.png";
import vendorLogo from "../../../images/images/capabilities/vendorLogo.png";
import blueprintingLogo from "../../../images/images/capabilities/blueprintLogo.png";
import "style/sidebar-custom.css";

function pxToRem(value) {
  return `${value / 16}rem`;
}

const breakpoints = createBreakpoints({});
const useStyles = makeStyles((theme) => ({
  // SIDEBAR
  sideBar: {
    backgroundColor: variables.ncgGray1,
    maxWidth: 300,
    minWidth: 300,
    width: "100%",
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    display: "inline-block",
    alignSelf: "flex-start",
    left: 0,
    position: "sticky",
    top: 70,
    maxHeight: "90vh",
    overflow: "auto",
    [theme.breakpoints.down('sm')]: {
      maxWidth: 240,
      minWidth: 240,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: "100%!important",
      width: "100%",
      minWidth: "unset",
      position: "relative",
      top: 0,
    },
  },
  sideBarH5: {
    marginTop: 20,
    marginBottom: 10,
  },
  sideBarWrapper: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ced8e2",
    justifyContent: "center",
    [theme.breakpoints.down('xs')]: {
      alignItems: "flex-start",
      paddingTop: 10,
      textAlign: "left",
      '&:first-of-type': {
        borderBottom: "none",
        paddingBottom: 20,
      },
    },
  },
  sideBarWrapperLast: {
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
    },
  },
  // SIDEBAR DRAWER
  drawerList: {
    padding: "30px 40px",
    [theme.breakpoints.down('sm')]: {
      padding: 30,
    },
  },
  sideBarList: {
    paddingLeft: 15,
    marginTop: 10,
    paddingBottom: 15,
    width: "fit-content",
  },
  sideBarListItem: {
    fontSize: "13px",
    maxWidth: 180,
    textAlign: "left",
    paddingTop: 10,
    lineHeight: "1.875em",
    letterSpacing: ".08em",
  },
  headingOver: {
    color: variables.ncgBlue3,
    fontSize: "1.6em",
    lineHeight: "1.125em",
    marginBottom: 5,
  },
  headingUnder: {
    color: variables.ncgBlue2,
    paddingTop: 0,
    display: "inline-block",
    marginTop: 5,
    marginBottom: 5,
  },
  // SERVICES LIST
  solutionsHeader: {
    paddingLeft: 30,
  },
  sideBarWrapperServices: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ced8e2",
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    [theme.breakpoints.down('xs')]: {
      display: "none",
    },
  },
  sideBarServicesList: {
    paddingLeft: 0,
    marginBottom: 0,
    marginTop: 0,
    width: "100%",
  },
  serviceItem: {
    width: "100%",
    listStyleType: "none",
    paddingTop: 0,
  },
  serviceItemLink: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 30,
    paddingRight: 30,
    borderBottom: "1px solid #d8dde3",
    alignItems: "flex-start",
    justifyContent: "center",
    '&:active': {
      backgroundColor: "white",
      borderBottom: "1px solid #ADD9F1",
    },
  },
  serviceLabel: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "normal",
    paddingTop: 0,
    paddingBottom: 0,
    color: variables.textPrimary,
    '&:active': {
      color: variables.ncgblue5,
    },
  },
  // SECTIONS
  mainContent: {
    display: "flex",
    position: "relative",
    flexDirection: "row",
    padding: theme.spacing(2),
    alignItems: "flex-start",
    paddingTop: 0,
    paddingBottom: 80,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
    },
  },
  mainContentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
    },
  },
  introSection: {
    paddingTop: "80px!important;",
    backgroundColor: "white",
    [theme.breakpoints.down('xs')]: {
      paddingTop: "40px!important;",
      paddingBottom: "40px!important;",
    },
  },
  linkWrapper: {
    paddingBottom: 10,
  },
  pageSection: {
    paddingTop: 60,
    paddingBottom: 60,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
  pageSectionStriped: {
    padding: "60px",
    borderBottom: "1px solid rgba(221, 221, 221, 0.62)",
    '&:nth-of-type(odd)': {
      backgroundColor: '#f7f9fc',
    },
    '&:last-of-type': {
      borderBottom: "none",
    },
    [theme.breakpoints.down('xs')]: {
      padding: "40px",
      '&:nth-of-type(even)': {
        backgroundColor: '#f7f9fc',
      },
      '&:nth-of-type(odd)': {
        backgroundColor: "white",
      },
    },
  },
  // ORCHESTRATION CARDS
  capabilityCard: {
    display: "flex",
    flexDirection: "column",
    padding: 40,
    marginTop: 20,
    marginBottom: 30,
    backgroundColor: "white",
    borderRadius: 6,
    boxShadow: "3px 3px 7px 0px rgb(0 0 0 / 30%)",
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "fit-content",
    width: "100%",
    position: "relative",
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column-reverse",
      marginBottom: 10,
    },
  },
  cardLogo: {
    display: "inline-block",
    height: 100,
    maxWidth: 400,
    width: "100%",
    position: "relative",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    [theme.breakpoints.down('sm')]: {
      height: 50,
      backgroundPosition: "left center",
    },
  },
  cybersecurity: {
    backgroundImage: "url(" + cybersecurityLogo + ")",
  },
  program: {
    backgroundImage: "url(" + programLogo + ")",
  },
  risk: {
    backgroundImage: "url(" + riskLogo + ")",
  },
  mergers: {
    backgroundImage: "url(" + mergersLogo + ")",
  },
  compliance: {
    backgroundImage: "url(" + complianceLogo + ")",
  },
  vendor: {
    backgroundImage: "url(" + vendorLogo + ")",
  },
  blueprinting: {
    backgroundImage: "url(" + blueprintingLogo + ")",
  },
  cardLabel: {
    display: "inline-block",
    marginLeft: "auto",
    maxWidth: 160,
    textAlign: "right",
    lineHeight: "1.5em",
    opacity: .8,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 10,
    },
  },
  cardListItem: {
    [breakpoints.down("sm")]: {
      fontSize: pxToRem(14),
      paddingTop: 5,
    },
  },
  // ORCHESTRATION LOGO
  orchLogoWrapper: {
    display: "block",
    maxWidth: 500,
    width: "100%",
    height: "fit-content",
    marginBottom: 20,
    marginTop: 20,
  },
}));

const ServicesCapabilitiesPage = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const openNav = (event) => {
    setOpen(true);
  }
  const closeNav = () => {
    setOpen(false);
  }

  useEffect(() => {
    function isElementInViewport(el) {
      var rect = el.getBoundingClientRect();
      return (
        rect.top <= 300 && rect.bottom >= 300
      );
    }
    document.onscroll = function () {
      let anchorList = document.getElementsByClassName("anchor-section")

      for (let item of anchorList) {
        let sectionLink = document.querySelectorAll(`a[href="#${item.id}"]`)       
        let sectionWrapper = sectionLink[0].parentElement
        if (isElementInViewport(item)) {
          sectionWrapper.classList.add("active-listitem")
        }
        else {
          if (sectionWrapper.classList.contains("active-listitem")) {
            sectionWrapper.classList.remove("active-listitem")
          }
        }
      }
    }
  })
  return (
    <>
      <BackToTopCustom />
      <Banner backgroundImage={capabilitiesBanner}>
        Capabilities</Banner>
      <PageWrapper >
        <Container maxWidth="lg" className={classes.mainContent}>
          <Grid xs={12} sm={12} md={12} lg={12} className={classes.mainContentWrapper}>
            <Grid item xs={12} sm={11} md={12} lg={12} className={classNames(classes.introSection, classes.pageSectionStriped)}>
              <Typography variant="h2">
                Overview
              </Typography>
              <Typography variant="body1">
                NCG provides services to help organizations meet the challenges of regulatory compliance, bridging between disparate groups, and working with technology and data to accomplish goals.
              </Typography>
              <Typography variant="body1">
                We craft our solutions to meet the needs of each client based on our proven experiences and suite of specific services. Our services include support, consultation, tools and accelerators for all parts of an organization.
              </Typography>
              <div className={classes.linkWrapper}>
                <LinkDefault href="/about-ncg/corporate-background">Learn more about our Corporate Background</LinkDefault>
              </div>
            </Grid>
            <div className={classNames(classes.pageSection, classes.spotlightContainer, "anchor-section")}>
              <Container maxWidth="lg" className={classes.mainContentSpotlight}>
                <Spotlight />
              </Container>
            </div>
          </Grid>
        </Container>
      </PageWrapper>
    </>

  )
}

export default ServicesCapabilitiesPage;