//fontawesome Icon
import AngleDoubleLeftIcon from "../../utils/angleDoubleLeftIcon";

//image
import cmmcLogo from "images/images/microsite/cmmc/cmmc-orchestration-logo-medium.png"

const CMMCBlogPost = (props) => {

  return (
    <>
      <div class="bootstrap crunch">
        <div id="top" class="banner banner--blog padZero" >
          <h1 class="bannerTitle">Preparation and Support for the Department of Defense Cybersecurity Maturity Model Certification</h1>
        </div>
        <div class="page-wrap">
          <div class="primary_layout">
            <div class="mainpage-wrapper blogPage">
              <a class="btn-link-back" href="/cmmc"><AngleDoubleLeftIcon /> Return to NCG CMMC Site</a>
              <div itemprop="mainContentOfPage" class="page-section">
                <div class="blogPost">
                  <div itemprop="dateCreated" class="date">June 30, 2020</div>
                  <p itemprop="text">
                    The Cybersecurity Maturity Model Certification (CMMC) is a new Department of Defense (DoD) process to ensure that all suppliers in the Defense Industrial Base have a base level of cybersecurity capability. DoD is planning to set CMMC Maturity Level requirements in RFPs starting in late 2020. DoD suppliers will need to be certified by a designated third party. CMMC self-certification will not be accepted. Suppliers that are not certified will not be able to win new DoD contracts individually or as part of a team.
                                </p>
                  <p>
                    At its foundation, the CMMC model covers basic cybersecurity safeguards that are applicable to almost any organization. The model uses Federal Acquisition Regulations (FAR) and Defense Federal Acquisition Regulation Supplement (DFARS) requirements, along with National Institute of Standards and Technology (NIST) specifications, for the protection of Federal Contract Information (FCI) and Controlled Unclassified Information (CUI) as it’s basis.
                                </p>
                  <h3>
                    Helping Organizations Prepare for Certification
                                </h3>
                  <p>
                    Northcross Group (NCG) has helped organizations over the past 14 years, across multiple industries, implement effective and sustainable cybersecurity programs to meet compliance requirements and better their overall security posture. NCG is a cybersecurity and system integration firm focused on compliance and risk management supporting both Federal and commercial clients.
                                </p>
                  <p>
                    For some companies, the CMMC process will be straightforward and similar to other maturity model frameworks and audits they currently support. For those with robust cybersecurity practices, but have not been through similar reviews, their efforts may focus on documentation and being able to represent their current state compared to the CMMC model. For other organizations, getting ready for certification could entail implementation and documentation of new practices and processes.
                                </p>
                  <p>
                    To help organizations know where they stand and identify what gaps they need to close for certification, NCG has developed a free online questionnaire to baseline their current cybersecurity state. NCG’s CMMC Baseline Questionnaire is a quick and understandable way for an organization to see what is needed to achieve certification.
                                </p>
                  <p>
                    NCG provides services and tools to help organizations prepare and implement practices for certification specifications. NCG is a recognized expert in the cybersecurity program development and implementation space—particularly for tailoring efforts to best meet the business needs and corporate culture of the organization.
                                </p>
                  <h3>
                    NCG’s CMMC Baseline Questionnaire
                                </h3>
                  <p>
                    NCG’s CMMC Baseline Questionnaire provides organizations with a simple and easy way to measure their current cybersecurity state relative to the CMMC model. Most organizations are already taking steps to protect their data systems. The Baseline Questionnaire helps organizations gauge their efforts align with the model’s specifications.
                                </p>
                  <p>
                    The CMMC model measures an organization based on five progressive levels of maturity, with Maturity Level 1 being the starting point and Maturity Level 5 being the highest certification level. The model requires 171 cybersecurity Practices that are organized by Capabilities and Domains. It also requires organizations to perform defined Processes that come into play at different Maturity Levels.
                                </p>
                  <p>
                    Understanding what you already have in place that can be used for certification, along with understanding where you have gaps, are both important to balance efforts to meet model requirements and get certified.
                                </p>
                  <h3>
                    Taking the Questionnaire
                                </h3>
                  <p>
                    You can create a free account to access NCG’s CMMC Baseline Questionnaire from the NCG CMMC website (<a href="/cmmc" target="_blank" rel="noreferrer">www.northcrossgroup.com/cmmc</a>) or directly at <a target="_blank" rel="noreferrer" href="https://cmmcquestionnaire.com/">www.CMMCQuestionnnaire.com</a>. Once you create your account, you can create additional accounts for anyone in your organization to help you complete the questionnaire.
                                </p>
                  <p>
                    The questionnaire asks about your current cybersecurity practices, documentation, and processes using the CMMC Domains and Capabilities. Questions start at a high-level by Domain, then go into more detail about specific Capabilities for those Domains you have in place today. You can revisit the Questionnaire, make changes, and easily navigate between Domains and Process questions as needed. Once you have responded to all the 17 CMMC Domains and Process questions, you can submit your responses.
                                </p>
                  <h3>
                    Questionnaire Results
                                </h3>
                  <p>
                    A Maturity Level Projection is calculated based on your questionnaire responses. The calculation is based on the model’s sequence of Domains, Capabilities, and Processes through Maturity Levels. To move up in levels, an organization must have accomplished all the items under all the previous levels. The projection provides a look at where your organization may come out in the certification process, as well as where you have capabilities that apply to subsequent maturity levels. The results map your current state to the CMMC model, providing insight into where investments can be made to best address model requirements.
                                </p>
                  <h3>
                    Next Steps after the Questionnaire
                                </h3>
                  <p>
                    Additionally, NCG can use the questionnaire results to provide an independent assessment of your readiness for certification at a more detailed level. NCG will look at the implementation and performance of specific CMMC Practices and Processes at your organization. NCG will review applicable documentation and interview key people at your organization to assess both design and performance. The entire assessment process can be conducted virtually.
                                </p>
                  <p>
                    The assessment process produces two deliverables. The first is a Projected Maturity Level compliance matrix. The matrix lists your practices and processes that are able to meet CMMC requirements. Any findings of areas not meeting requirements are highlighted. The second deliverable is a CMMC Roadmap for your specific organization to address findings and the steps each the next CMMC Maturity Level. The Roadmap provides you with estimated levels of effort and resources to help you plan and prioritize efforts with a business focus on ROI.
                                </p>
                  <div class="col-lg-7 col-md-7 col-sm-8 col-xs-6 cmmc-logo-container">
                    <img src={cmmcLogo} alt="cmmc logo" class="img-responsive graphic graphic--desktop" />
                  </div>
                  <h3>
                    Certification Preparation and Implementation Services
                                </h3>
                  <p>
                    NCG helps organizations accomplish certification objectives in a managed and targeted manner that makes the most business sense for your company’s unique circumstances. NCG’s CMMC Orchestration services follow NCG’s proven delivery model that integrates people, data, technology, and processes—focused on the delivery of business objectives.
                                </p>
                  <p>
                    We work with your team to make a plan with prioritization to fit your needs. We leverage automation and tools to accelerate efforts and leverage technology prudently. We will engage with all parts of the organization to ensure efforts are sustainable and provide benefits to the organization’s overall cybersecurity posture as well as meeting certification requirements.
                                </p>
                  <div class="linkWrapper">
                    <a href='/'>www.northcrossgroup.com</a>
                    <a href="mailto:info@northcrossgroup.com">info@northcrossgroup.com</a>
                    <p>
                      NCG’s Free CMMC Baseline Questionnaire: <a target="_blank" rel="noreferrer" href="https://cmmcquestionnaire.com/">www.CMMCQuestionnnaire.com</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CMMCBlogPost;