//images
import introDesktop from "images/images/banking/analytics/intro.jpg"
import introMobile from "images/images/banking/analytics/mobile/intro.jpg"
import analyticsDesktop from "images/images/banking/analytics/bottom.jpg"
import analyticsMobile from "images/images/banking/analytics/mobile/bottom.jpg"

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";

const Analytics = (props) => {
  return (
    <>
      <BackToTopCustom />
      <div class="bootstrap crunch">
        <div id="top" class="banner banner--analytics"  >
          <h1 class="bannerTitle ">Analytics</h1>
        </div>
        <div class="page-wrap ">
          <div class="secondary_layout secondary_layout--full">
            <div itemprop="breadcrumb" class="breadcrumb-wrapper">
              <ul class="breadcrumb">
                <li><a href="/capabilities">Services &amp; Capabilities</a></li>
                <li><a href="/banking-financial">Banking &amp; Financial Services</a></li>
                <li class="active">Analytics</li>
              </ul>
            </div>
            <div itemprop="mainContentOfPage" class="mainpage-wrapper">
              <div class="page-section">
                <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <img src={introDesktop} class="img-responsive img-rounded hidden-sm hidden-xs" alt="Businessman Checking Graph" />
                  <img src={introMobile} class="img-responsive img-rounded hidden-lg hidden-md" alt="Businessman Checking Graph" />
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <h3 class="heading-h3">Overview</h3>
                  <p>The ability to leverage data can differentiate the delivery of products and services to customers and drive operational processes. NCG has the technical capabilities matched with banking expertise to help your organization unlock the potential in your data and put it to practical use. Financial institutions have multiple data processing life cycles that interface for different processes based on transaction and account types. With a foundation in understanding banking processes and system architectures, NCG helps organizations tap into the value of their data to drive better customer interaction and operational procedures.</p>
                </div>
              </div>
              <div class="page-section">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <h2 class="heading-h2">Analytics: Provided Services</h2>
                </div>
                <div class="graphic-wrap graphic-wrap--right col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <img src={analyticsDesktop} class="img-responsive img-rounded hidden-sm hidden-xs" alt="Graph on Device" />
                  <img src={analyticsMobile} class="img-responsive img-rounded hidden-lg hidden-md" alt="Graph on Device" />
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <p>Developing a full-spectrum picture of existing customers and potential markets helps institutions capture greater wallet share and provide products and services with the best value proposition. This data drives customer relationship efforts today and helps direct development initiatives for the future. Data may reside in multiple locations and analysis requires consideration for processing, rules, and regulations.</p>
                  <p>NCG helps financial institutions with banking SME, data scientist and operational analytics support and development aligned with existing processes and frameworks. NCG supports assessment and reviews of AML\BSA systems and processing forensics to support investigations and continuous improvement efforts. NCG also provides ad hoc expertise and data mining to support regulatory compliance research and development of governance reporting and analysis.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Analytics;