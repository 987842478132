import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Container, Link, MenuList, Drawer } from "@material-ui/core";
import React, { useState } from "react";
import LinkDefault from "components/layout/linkDefault.component";
import PageWrapper from "components/layout/pageWrapper.component";
import BackToTopCustom from "components/layout/BackToTopCustom";
import Banner from "components/layout/banner.component";
import ContentContainer from "components/layout/contentContainer.component";
import Image from "components/layout/image.component";
import classNames from "classnames";
import variables from "../../../style/variables.module.scss";
// IMAGES
import aboutBanner from "../../../images/images/banners/about.jpg";
import nmuLogo from "../../../images/images/nmu-partnership/upci-logo.png";
import inc5000 from "../../../images/images/about/inc5000BlackMedallion.png";
import chrisBender from "../../../images/images/about/management/bender.jpg";
import davidWininger from "../../../images/images/about/management/wininger.jpg";
import lisaMavilia from "../../../images/images/about/management/mavilia.jpg";
import kevinDesorbo from "../../../images/images/about/management/desorbo.jpg";
import ricoSimons from "../../../images/images/about/management/simons.jpg";

const useStyles = makeStyles((theme) => ({
  // SIDEBAR
  sideBar: {
    backgroundColor: variables.ncgGray1,
    maxWidth: 300,
    minWidth: 300,
    width: "100%",
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    display: "inline-block",
    alignSelf: "flex-start",
    left: 0,
    position: "sticky",
    top: 70,
    maxHeight: "90vh",
    overflow: "auto",
    [theme.breakpoints.down('sm')]: {
      maxWidth: 260,
      minWidth: 260,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: "100%!important",
      width: "100%",
      minWidth: "unset",
      position: "relative",
      top: 0,
    },
  },
  sideBarH5: {
    marginTop: 20,
    marginBottom: 10,
    textAlign: "center",
  },
  sideBarWrapper: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ced8e2",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      alignItems: "flex-start",
      paddingTop: 10,
      textAlign: "left",
    },
  },
  teamInfo: {
    visibility: "hidden",
    position: "fixed",
    width: 250,
    transition: "all 300ms ease 0s",
    height: "100%",
    top: 150,
    left: 0,
    zIndex: 1049,
  },
  sideBarList: {
    paddingLeft: 30,
    marginTop: 0,
    width: "fit-content",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      paddingLeft: 25,
    },
  },
  sideBarListItem: {
    fontSize: "13px",
    maxWidth: 180,
    textAlign: "left",
    paddingTop: 10,
    lineHeight: "1.875em",
    letterSpacing: ".08em",
  },
  sideBarBody: {
    padding: 10,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  // TEAM MEMBER LIST
  sideBarWrapperTeam: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ced8e2",
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    textAlign: "center",
    [theme.breakpoints.down('xs')]: {
      display: "none",
    },
  },
  sideBarTeamList: {
    paddingLeft: 0,
    marginBottom: 0,
    marginTop: 0,
    width: "100%",
  },
  memberItem: {
    width: "100%",
    listStyleType: "none",
    paddingTop: 0,
  },
  memberItemLink: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
    borderTop: "1px solid transparent",
    borderBottom: "1px solid #d8dde3",
    alignItems: "flex-start",
    justifyContent: "center",
    '&:active': {
      backgroundColor: "white",
      borderBottom: "1px solid #ADD9F1",
      borderTop: "1px solid #ADD9F1",
    },
  },
  memberWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 60,
    position: "relative",
    alignItems: "flex-start",
    '&::before': {
      display: "inline-block",
      height: 40,
      width: 40,
      content: '""',
      position: "absolute",
      top: "50%",
      left: 0,
      transform: "translateY(-50%)",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
    },
  },
  bender: {
    '&::before': {
      backgroundImage: "url(" + chrisBender + ")",
    },
  },
  wininger: {
    '&::before': {
      backgroundImage: "url(" + davidWininger + ")",
    }
  },
  mavilia: {
    '&::before': {
      backgroundImage: "url(" + lisaMavilia + ")",
    }
  },
  desorbo: {
    '&::before': {
      backgroundImage: "url(" + kevinDesorbo + ")",
    }
  },
  simons: {
    '&::before': {
      backgroundImage: "url(" + ricoSimons + ")",
    }
  },
  memberNameHeader: {
    fontSize: 11,
    paddingLeft: 0,
    display: "inline-block",
    fontWeight: "bold",
    color: variables.ncgTeal2,
    lineHeight: "1.6em",
    marginTop: 0,
    marginBottom: 5,
    letterSpacing: ".15em",
    textAlign: "left",
  },
  memberPosition: {
    fontWeight: 400,
    color: variables.textPrimary,
    marginBottom: 0,
  },
  // TEAM INFORMATION DRAWER
  drawerList: {
    padding: 50,
    [theme.breakpoints.down('sm')]: {
      padding: 30,
    },
  },

  // SECTIONS
  mainContent: {
    display: "flex",
    position: "relative",
    flexDirection: "row",
    padding: theme.spacing(2),
    alignItems: "flex-start",
    paddingTop: 0,
    paddingBottom: 80,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
    },
  },
  mainContentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginLeft: "auto",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
    },
  },
  introSection: {
    paddingTop: "80px!important;",
    paddingBottom: "80px!important;",
    [theme.breakpoints.down('xs')]: {
      paddingTop: "40px!important;",
      paddingBottom: "40px!important;",
    },
  },
  subSection: {
    paddingTop: 30,
  },
  linkWrapper: {
    paddingBottom: 10,
  },
  inlineLinkWrapper: {
    display: "inline-flex",
    alignItems: "center",
    width: "fit-content",
    whiteSpace: "pre-wrap",
    marginTop: 0,
  },
  pageSection: {
    paddingTop: 60,
    paddingBottom: 60,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  pageSectionStriped: {
    padding: "60px",
    borderBottom: "1px solid rgba(221, 221, 221, 0.62)",
    '&:nth-of-type(odd)': {
      backgroundColor: '#f7f9fc',
    },
    '&:last-of-type': {
      borderBottom: "none",
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: "center"
    },
    [theme.breakpoints.down('xs')]: {
      padding: "40px",
    },
  },
  pageSectionWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  // WRAP SMALL
  linkWrapperFloatSmall: {
    float: "left",
    maxWidth: 160,
    marginRight: 40,
    marginTop: 25,
    display: "inline-block",
    [theme.breakpoints.down('sm')]: {
      float: "none",
      marginRight: 0,
    },
  },
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  noMargin: {
    marginBottom: 0,
    marginTop: 0,
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
    marginRight: 40,
    maxWidth: 85,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: 10,
      maxWidth: 200,
      alignSelf: "center",
      marginTop: "-30px",
    },
  },
  headingOver: {
    fontSize: "2.5em",
    lineHeight: "1.125em",
    color: variables.ncgBlue3,
  },
  headingUnder: {
    color: variables.ncgBlue2,
    paddingTop: 0,
    display: "inline-block",
    marginTop: 5,
    marginBottom: 5,
  },
  headingSample: {

  },
  // OFFICE LOCATIONS
  locationsContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  locationCell: {
    padding: 25,
    '&:first-of-type': {
      paddingLeft: 0,
    },
    '&:last-of-type': {
      paddingRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      padding: "25px 25px 10px 25px",
      '&:first-of-type': {
        paddingLeft: 25,
      },
      '&:last-of-type': {
        paddingRight: 25,
      },
      alignItems: "center",
      justifyContent: "center",
    },
  },
  listAddress: {
    padding: 0,
    margin: 0,
    listStyleType: "none",
    [theme.breakpoints.down('sm')]: {
      display: "inline-block",
    }
  },
  listAddressItem: {
    padding: "3px 0px",
    fontSize: 13,
    lineHeight: "1.1em",
  },
  // TEAM
  teamMemberName: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column"
    },
  },
  teamMemberPhoto: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 170,
    },
  },
  nameHeader: {
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  marginBottom: {
    marginBottom: 50,
    marginTop: 20,
  },
  profileLink: {
    color: "#2773bd",
    fontWeight: 300,
    display: "flex",
    alignItems: "center",
    lineHeight: "normal",
    marginTop: 10,
  },
  overviewIcon: {
    fontSize: "1.2em",
    marginRight: 5,
  },
}));

const AboutPage = () => {

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const openNav = (event) => {
    setOpen(true);
  }
  const closeNav = () => {
    setOpen(false);
  }
  return (
    <>
      <BackToTopCustom />
      <Banner backgroundImage={aboutBanner}>
        About NCG</Banner>
      <PageWrapper >
        <Container maxWidth="lg" className={classes.mainContent}>
          <Grid item xs={12} sm={3} md={3} lg={3} className={classes.sideBar}>
            <div className={classes.sideBarWrapper}>
              <Typography variant="h5" className={classes.sideBarH5}>Career Opportunities</Typography>
              <Typography variant="body2" className={classes.sideBarBody}>We are currently looking for experienced developers and consultants!</Typography>
              <LinkDefault variant="defaultMini" href="/about-ncg/career-opportunities/careers">Learn More About NCG Careers</LinkDefault>
              <Typography variant="h5" className={classes.sideBarH5}>Team Information</Typography>
              <LinkDefault variant="drawerLeft" className={classes.profileLink}
                disableElevation
                id="demo-positioned-button"
                aria-controls="demo-positioned-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={() => openNav()}> Profile Overview</LinkDefault>
              <Drawer
                anchor='left'
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                open={open}
                onClose={closeNav}
              >
                <MenuList
                  className={classes.drawerList}
                >
                  <Typography variant="h5" className={classes.sideBarH5}>Team Profile</Typography>
                  <ul className={classes.sideBarList}>
                    <li className={classes.sideBarListItem}>
                      Private, Federal, and Defense Management Experience
                    </li>
                    <li className={classes.sideBarListItem}>
                      Data Analysis and Assessment Expertise
                    </li>
                    <li className={classes.sideBarListItem}>
                      System Design, Development, and Implementation Expertise
                    </li>
                    <li className={classes.sideBarListItem}>
                      Enterprise and Mission Critical System Operations Experience
                    </li>
                    <li className={classes.sideBarListItem}>
                      Business and Technology Integration Expertise
                    </li>
                    <li className={classes.sideBarListItem}>
                      SBA 8(a) Certified
                    </li>
                  </ul>
                </MenuList>
              </Drawer>
            </div>
            <div className={classNames(classes.sideBarWrapperTeam)}>
              <Typography variant="h5" className={classes.sideBarH5}>Leadership Team</Typography>
              <ul className={classes.sideBarTeamList}>
                <li className={classes.memberItem}>
                  <Link href="#bender" className={classNames(classes.memberItemLink)}>
                    <div className={classNames(classes.memberWrapper, classes.bender)}>
                      <Typography variant="h5" className={classes.memberNameHeader}>
                        Chris Bender
                      </Typography>
                      <Typography variant="h5" className={classNames(classes.memberPosition, classes.memberNameHeader)}>
                        President
                      </Typography>
                    </div>
                  </Link>
                </li>
                <li className={classes.memberItem}>
                  <Link href="#wininger" className={classNames(classes.memberItemLink)}>
                    <div className={classNames(classes.memberWrapper, classes.wininger)}>
                      <Typography variant="h5" className={classes.memberNameHeader}>
                        David Wininger
                      </Typography>
                      <Typography variant="h5" className={classNames(classes.memberPosition, classes.memberNameHeader)}>
                        Board Advisor
                      </Typography>
                    </div>
                  </Link>
                </li>
                <li className={classes.memberItem}>
                  <Link href="#mavilia" className={classNames(classes.memberItemLink)}>
                    <div className={classNames(classes.memberWrapper, classes.mavilia)}>
                      <Typography variant="h5" className={classes.memberNameHeader}>
                        Lisa Mavilia
                      </Typography>
                      <Typography variant="h5" className={classNames(classes.memberPosition, classes.memberNameHeader)}>
                        Chief Human Resources Officer
                      </Typography>
                    </div>
                  </Link>
                </li>
                <li className={classes.memberItem}>
                  <Link href="#desorbo" className={classNames(classes.memberItemLink)}>
                    <div className={classNames(classes.memberWrapper, classes.desorbo)}>
                      <Typography variant="h5" className={classes.memberNameHeader}>
                        Kevin DeSorbo
                      </Typography>
                      <Typography variant="h5" className={classNames(classes.memberPosition, classes.memberNameHeader)}>
                        Chief Technology Officer
                      </Typography>
                    </div>
                  </Link>
                </li>
                <li className={classes.memberItem}>
                  <Link href="#simons" className={classNames(classes.memberItemLink)}>
                    <div className={classNames(classes.memberWrapper, classes.simons)}>
                      <Typography variant="h5" className={classes.memberNameHeader}>
                        Rico Simons
                      </Typography>
                      <Typography variant="h5" className={classNames(classes.memberPosition, classes.memberNameHeader)}>
                        Chief Client Engagement Officer
                      </Typography>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </Grid>
          <Grid xs={12} sm={9} md={9} lg={9} className={classes.mainContentWrapper}>
            <Grid item xs={12} sm={11} md={11} lg={11} className={classNames(classes.introSection, classes.pageSectionStriped)}>
              <Typography variant="h2">
                Overview
              </Typography>
              <Typography variant="body1">
                Northcross Group (NCG) is a professional services firm specializing in merger and acquisition integrations, system modernization, and compliance programs for clients in the private and public sectors. NCG services are focused on helping clients with governance, risk, and compliance and works across financial services, healthcare, manufacturing. energy, defense, aerospace, and transportation sectors. NCG works with clients in highly regulated industries, and companies with customers in those industries or that work with the US Federal Government or Department of Defense. NCG provides industry-specific business solutions and understands the importance of providing the same level of care to the people who will use it and the underlying data. These fundamentals—technology, data, and people—are all a part of the equation for success. NCG develops tooling and automated platforms to support its professional services practices has a SaaS platform that makes its tooling available to broader markets.
              </Typography>
              <Typography variant="body1">
                Since 2006, NCG has been empowering its clients to use technology to improve operations, expand market positions, and increase revenues. NCG consultants have decades of experience and achieve tangible results using a collaborative and responsive approach to problem-solving. Team members have contract experience at federal and state levels as well as civilian, defense, and intelligence clearances. Consequently, NCG consultants have the perspective to assess needs, risks, and issues; the skills to prioritize work items; and the expertise to deliver results.
              </Typography>
              <div className={classes.linkWrapper}>
                <LinkDefault href="/about-ncg/corporate-background">Learn more about our Corporate Background</LinkDefault>
              </div>
            </Grid>

            <Grid item xs={12} sm={11} md={11} lg={11} className={classNames(classes.subSection, classes.pageSectionStriped, classes.pageSectionWrapImage)}>
                <Link href="https://www.inc.com/inc5000" target="_blank" className={classes.linkWrapperFloatSmall}>
                    <Image url={inc5000} variant="customWidth" width={"100%"} />
                </Link>
                <Grid item md={12} lg={12} className={classes.headerWrapper}>
                  <Typography variant="h3" className={classes.noMargin}>
                    NCG on the 2024 Inc. 5000 List
                  </Typography>
                </Grid>
                <Typography variant="body1">
                  NCG ranks No. 1706 on the 2024 Inc. 5000, its annual list of the fastest-growing private companies in America. The prestigious ranking provides a data-driven look at the most successful companies within the economy’s most dynamic segment—its independent, entrepreneurial businesses. Microsoft, Meta, Chobani, Under Armour, Timberland, Oracle, Patagonia, and many other household-name brands gained their first national exposure as honorees on the Inc. 5000.
                </Typography>
                <Typography variant="body1">
                  With Three-Year Revenue Growth of 305% Percent, NCG—a State of Maine Corporation, Ranks No. 5 in Maine and No. 178 in Business Products & Services Segment Among America’s Fastest-Growing Private Companies. The full Inc. 5000 list for 2024 and selection criteria can be found at <LinkDefault className={classes.inlineLinkWrapper} href="https://www.inc.com/inc5000">www.inc.com/inc5000</LinkDefault>
                </Typography>
                <div className={classes.linkWrapper}>
                  <LinkDefault className={classes.linkWrapper} href="https://www.inc.com/inc-custom-studio/a-focus-on-complete-solutions-for-regulatory-environments/90985892">NCG Inc. Magazine Profile November 2024</LinkDefault>
                </div>
            </Grid>


            <Grid item xs={12} sm={11} md={11} lg={11} className={classNames(classes.subSection, classes.pageSectionStriped)}>
              <Grid item md={12} lg={12} className={classes.headerWrapper}>
                <Grid item sm={10} md={2} lg={2} className={classes.logoWrapper}>
                  <ContentContainer variant="customWidth">
                    <Image url={nmuLogo} variant="customWidth" width={"100%"} />
                  </ContentContainer>
                </Grid>
                <Grid item md={9} lg={9} className={classes.header}>
                  <Typography variant="h3" className={classes.noMargin}>
                    NCG Academic Partnership with the Upper Peninsula Cybersecurity Institute at Northern Michigan University
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="body1">
                NCG has an Academic Partnership with Northern Michigan University's Upper Peninsula Cybersecurity Institute. This partnership began in September 2020 and is focused on helping develop the next generation of cybersecurity professionals with NCG's business centric approach to cybersecurity that tailors efforts to a client's industry, size, and complexity.
              </Typography>

              <div className={classes.linkWrapper}>
                <LinkDefault href="/nmu-partnership">Learn more about our Academic Partnership</LinkDefault>
              </div>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} className={classNames(classes.subSection, classes.pageSectionStriped)}>
              <Typography variant="h2" className={classes.headingOver}>
                NCG - Office Locations
              </Typography>
              <Grid xs={12} sm={12} md={11} lg={11} className={classes.locationsContainer}>
                <Grid lg={4} className={classes.locationCell}>
                  <Typography variant="h5" className={classes.headingUnder}>
                    PORTLAND, MAINE OFFICE
                  </Typography>
                  <Typography variant="body1">
                    The NCG headquarters are located in
                    Portland, Maine on 100 Middle Street.
                  </Typography>
                  <ul className={classes.listAddress}>
                    <li className={classes.listAddressItem}>
                      100 Middle St.
                    </li>
                    <li className={classes.listAddressItem}>
                      East Tower, #203
                    </li>
                    <li className={classes.listAddressItem}>
                      Portland, ME 04101
                    </li>
                    <li className={classes.listAddressItem}>
                      207.699.5540
                    </li>
                    <li className={classes.listAddressItem}>
                      FAX 207.699.2113
                    </li>
                  </ul>
                </Grid>
                <Grid lg={4} className={classes.locationCell}>
                  <Typography variant="h5" className={classes.headingUnder}>
                    DC METRO OFFICE
                  </Typography>
                  <Typography variant="body1">
                    NCG has an office located in Arlington, VA on Wilson Boulevard.
                  </Typography>
                  <ul className={classes.listAddress}>
                    <li className={classes.listAddressItem}>
                      1101 Wilson Blvd.
                    </li>
                    <li className={classes.listAddressItem}>
                      6th Floor
                    </li>
                    <li className={classes.listAddressItem}>
                      Arlington, VA 22209
                    </li>
                    <li className={classes.listAddressItem}>
                      703.351.3397
                    </li>
                    <li className={classes.listAddressItem}>
                      FAX 703.351.5298
                    </li>
                  </ul>
                </Grid>
                <Grid lg={4} className={classes.locationCell}>
                  <Typography variant="h5" className={classes.headingUnder}>
                    MARQUETTE, MI OFFICE
                  </Typography>
                  <Typography variant="body1">
                    NCG has an office located in Marquette, MI on Washington Street.
                  </Typography>
                  <ul className={classes.listAddress}>
                    <li className={classes.listAddressItem}>
                      101 W. Washington St.
                    </li>
                    <li className={classes.listAddressItem}>
                      Suite #4
                    </li>
                    <li className={classes.listAddressItem}>
                      Marquette, MI 49855
                    </li>
                    <li className={classes.listAddressItem}>
                      906.451.5910
                    </li>
                  </ul>
                </Grid>
              </Grid>
              <div className={classes.linkWrapper}>
                <LinkDefault href="/about-ncg/contact">View NCG Office Locations</LinkDefault>
              </div>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} className={classNames(classes.subSection, classes.pageSectionStriped)}>
              <Typography variant="h2" className={classes.marginBottom}>
                Leadership Team
              </Typography>
              <div className={classes.teamMemberName} id="bender">
                <Grid item lg={2} md={3} sm={12} className={classes.teamMemberPhoto}>
                  <ContentContainer className={classes.imageWrapper} variant="customWidth">
                    <Image url={chrisBender} variant="customWidth" width={"100%"} />
                  </ContentContainer>
                </Grid>
                <Typography variant="h3" className={classes.nameHeader}>
                  Christopher Bender
                </Typography>
              </div>
              <Typography variant="body1">
                Christopher Bender is a governance, risk, and compliance system professional with over 35 years of experience in the public and private sector, working across financial services, healthcare, manufacturing. energy, defense, aerospace, and transportation sectors. Mr. Bender is the president of Northcross Group (NCG), a Portland, Maine headquartered professional services firm.  NCG works with clients in highly regulated industries, and companies with customers in those industries or that work with the US Federal Government or Department of Defense.    Mr. Bender helps clients implement new technology bridging people, data, and capabilities in a secure, compliant, and risk managed manner.  Mr. Bender is a Certified Information Systems Security Professional (CISSP) and a Certified Data Privacy Solutions Engineer (CDPSE) with a Masters of Science in Information Systems and a Bachelors of Arts in Economics from GW University.  Mr. Bender was adjunct faculty at GW from 1994-1995 in the Columbia College of Arts & Science, and Graduate Program instructor for the Engineering School’s Risk Management program from 2012-2017. 
              </Typography>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11} className={classNames(classes.subSection, classes.pageSectionStriped)}>
              <div className={classes.teamMemberName} id="wininger">
                <Grid item lg={2} md={3} sm={12}  className={classes.teamMemberPhoto}>
                  <ContentContainer className={classes.imageWrapper} variant="customWidth">
                    <Image url={davidWininger} variant="customWidth" width={"100%"} />
                  </ContentContainer>
                </Grid>
                <Typography variant="h3" className={classes.nameHeader}>
                  David Wininger
                </Typography>
              </div>
              <Typography variant="body1">
                David Wininger is the Board Advisor of Northcross Group. He has gained notoriety in the public and private sectors as an expert and seasoned practitioner of systems implementation, business continuity planning, disaster recover, operations, and security. He has held executive positions in private firms and in the federal government, maintaining a hands-on command of current technologies and management practices.
              </Typography>
              <Typography variant="body1">
                Mr. Wininger began his career with the Marine Corps in 1971. After twenty-one years of service, he worked as the Director of Special Operations Engineering program, managing the development and operational testing of small arms and automatic weapons. Before working at NCG, Mr. Wininger also served as the Director of Information Technology for the U.S. Strategic Weapons Program, including time as the Director of the Space and Special Welfare Systems Command Information Technology. Mr. Wininger received a BS in Engineering from the University of Maryland and a Master of Science in Computer and Electrical Engineering from Johns Hopkins University. He also graduated from the Federal Law Enforcement Training Center in Glynco, Georgia.
              </Typography>
            </Grid>
            <Grid itemxs={12} sm={11} md={11} lg={11} className={classNames(classes.subSection, classes.pageSectionStriped)}>
              <div className={classes.teamMemberName} id="mavilia">
                <Grid item lg={2} md={3} sm={12}  className={classes.teamMemberPhoto}>
                  <ContentContainer className={classes.imageWrapper} variant="customWidth">
                    <Image url={lisaMavilia} variant="customWidth" width={"100%"} />
                  </ContentContainer>
                </Grid>
                <Typography variant="h3" className={classes.nameHeader}>
                  Lisa Mavilia
                </Typography>
              </div>
              <Typography variant="body1">
                Lisa Mavilia is a seasoned Human Resources professional with over 10 years of experience overseeing both day-to-day and strategic HR operations for professional service firms. As the CHRO, Lisa ensures that the HR strategies are closely aligned with NCG’s broader business objectives, creating a cohesive approach to talent management and organizational growth. The most valuable part of any organization is its people and Lisa has a passion for fostering a positive workplace culture and facilitating employee development. In collaboration with executive leadership, Lisa is responsible for the design and execution of NCG’s recruiting strategy, hiring practices, mentorship program, and performance management practices.
              </Typography>
              <Typography variant="body1">
                Working directly with NCG clients and NCG’s business developers, Lisa plays a lead role with resource forecasting and management across new and existing client engagements including contract and relationship management, resource tracking, terms and conditions conformance, and status reporting. She is instrumental in managing HR and business compliance including adherence to federal, state, and local labor regulations, payroll, Workers' Compensation, leave programs, and manages all aspects of employee benefits. Lisa’s proactive management, compliance experience, and engagement with NCG employees allow her to create and implement policies and practices that meet business needs while fostering an engaging and inclusive workplace culture.
              </Typography>
            </Grid>
            <Grid itemxs={12} sm={11} md={11} lg={11} className={classNames(classes.subSection, classes.pageSectionStriped)}>
              <div className={classes.teamMemberName} id="desorbo">
                <Grid item lg={2} md={3} sm={12} className={classes.teamMemberPhoto}>
                  <ContentContainer className={classes.imageWrapper} variant="customWidth">
                    <Image url={kevinDesorbo} variant="customWidth" width={"100%"} />
                  </ContentContainer>
                </Grid>
                <Typography variant="h3" className={classes.nameHeader}>
                  Kevin DeSorbo
                </Typography>
              </div>
              <Typography variant="body1">
                Kevin DeSorbo is an experienced Information Technology professional with a wealth of experience in infrastructure and development within the commercial banking and private sectors. As the Infrastructure Director for Northcross Group (NCG), he is responsible for the network operations, server and network infrastructure, spanning on-premise and cloud operations, information security, compliance, database management, as well as supporting DevOps.
              </Typography>
              <Typography variant="body1">
                Mr. DeSorbo manages database administration and risk management for Northcross Group clients across an array of industries, educating clients about potential risks and implementing solutions. Other areas of expertise include vulnerability scanning, penetration testing, and implementation of configuration policies using federal standards, guidelines, and best practices. Mr. DeSorbo has capabilities in several programming languages including Java, C#, Python, PHP, ColdFusion, and JavaScript, and has become a major proponent of the NCG code development pipeline. He also has experience leading testing efforts to include manual and automated testing, unit system performance, and end-to-end testing.
              </Typography>
              <Typography variant="body1">
                Prior to joining NCG Mr. DeSorbo worked as the Director of Technology in the education sector and was responsible for the management and oversight of the network infrastructure and security. Mr. DeSorbo was instrumental in implementing on-site server updates and replacement, migration of critical systems to the cloud, and procurement and maintenance of workstations for over 300 clients.
              </Typography>
              <Typography variant="body1">
                Mr. DeSorbo holds a Bachelor's degree in Computer Science from Lehigh University.
              </Typography>
            </Grid>
            <Grid itemxs={12} sm={11} md={11} lg={11} className={classNames(classes.subSection, classes.pageSectionStriped)}>
              <div className={classes.teamMemberName} id="simons">
                <Grid item lg={2} md={3} sm={12}  className={classes.teamMemberPhoto}>
                  <ContentContainer className={classes.imageWrapper} variant="customWidth">
                    <Image url={ricoSimons} variant="customWidth" width={"100%"} />
                  </ContentContainer>
                </Grid>
                <Typography variant="h3" className={classes.nameHeader}>
                  Rico Simons
                </Typography>
              </div>
              <Typography variant="body1">
                Rico Simons is a Chief Client Engagement Officer with 25+ years of experience in Information Technology covering various industries. Mr. Simons provides expert-level experience business development, PMO and PIO development/governance and program/project management, focused on delivery throughput, quality assurance and cost containment models. Mr. Simons has worked with clients in the financial services, retail, logistics, transportation, oil field and retail industries. Mr. Simons assists clients in addressing major challenges and opportunities associated with program and project delivery approaches covering mergers and acquisitions, technology, systems integration, risk management, and compliance. 
              </Typography>
              <Typography variant="body1">
                Mr. Simons has provided PMO, PIO, program, project management leadership and business development for 50+ mergers and acquisitions working across the acquisition life cycle from due diligence to data conversion, system integration, business development and OCM (organizational change management). He has led over 30+ core banking and primary banking system evaluations and implementations over the past 15 years working with national and regional financial institutions. Mr. Simons has led, developed, and governed PMOs and PIOs for various industries, creating PMOs and PIOs from the ground-up. 
              </Typography>
              <Typography variant="body1">
                Rico Simons is a certified Project Management Professional (PMP) and certified Information Technology Infrastructure Library (ITIL) professional. Mr. Simons holds a Bachelor of Science in Computer Science and Telecommunications and Master Certificates from George Washington University in Project Management.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </PageWrapper>
    </>
  )
}

export default AboutPage;