import AdobeFileLogo from "../../utils/AdobeFileLogo";

//images
import integrationImg from "images/images/integration/right.jpg";

//back to top component
import BackToTopCustom from "components/layout/BackToTopCustom";

const IntegrationMicrosite = (props) => {

  return (
    <>
      <BackToTopCustom />
      <div class="bootstrap">
        <div class="crunch">
          <div id="top" class="banner banner--integration">
            <h1 class="bannerTitle" >Integration Services</h1>
          </div>
          <div class="page-wrap">
            <div class="secondary_layout secondary_layout--full">
              <div itemprop="breadcrumb" class="breadcrumb-wrapper">
                <ul class="breadcrumb">
                  <li><a href="/capabilities">Services &amp; Capabilities</a></li>
                  <li class="active">Integration Services</li>
                </ul>
              </div>
              <div id="intro" class="page-section">
                <div itemprop="mainContentOfPage" class="mainpage-wrapper">
                  <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <h3 class="heading-h3">Overview</h3>
                    <h2 class="heading-h2">Integration Services</h2>
                    <p>As businesses embark on merger, acquisition, and divestiture endeavors, they must integrate their systems, people, and data effectively to succeed. Moreover, they must oversee technical and security planning, testing, and implementation. NCG offers integration services that span the system development lifecycle, supporting technological and organizational needs, human factors, and data requirements.
                        </p>
                    <p>First, our consultants review and streamline existing processes and provide training to facilitate successful transition through a system deployment. Next, consultants implement the <a href="/orchestration-platform">Orchestration Approach</a>, using NCG’s web-based tool to ensure easy and secure communication across the organization. The Orchestration approach also allows consultants to address compliance requirements, including FISMA, SAS 70, SOX, and HIPAA. NCG’s integration services empower organizations to organize projects and programs, identify potential hazards, and address challenges for all industries.</p>
                    <div class="graphic-wrap graphic-wrap--left marginT col-pad-none col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <img id="integrationImg" class="img-responsive graphic " src={integrationImg} alt="Business people using device" />
                    </div>
                  </div>

                  <div itemscope itemtype="http://schema.org/WPSidebar" class="sideBar col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="wrapper">
                      <h5>Service Card</h5>

                      <ul class="list">
                        <li><a itemprop="relatedLink" target="_blank" rel="noreferrer" href="/content/docs/ServiceCards/NCG_M_A_Integration_Services_2014.pdf">Integration Services</a></li>
                        <li><a itemprop="relatedLink" target="_blank" rel="noreferrer" href="/content/docs/ServiceCards/NCG_CIO_CTO.pdf">CIO/CTO Services</a></li>
                        <li><a itemprop="relatedLink" target="_blank" rel="noreferrer" href="/content/docs/ServiceCards/NCG_Compliance_Governance.pdf">Compliance &amp; Governance</a></li>
                        <li><a itemprop="relatedLink" target="_blank" rel="noreferrer" href="/content/docs/ServiceCards/NCG_Data_Services.pdf">Data Services</a></li>
                        <li><a itemprop="relatedLink" target="_blank" rel="noreferrer" href="/content/docs/ServiceCards/NCG_HC_Rev_Cycle.pdf">Healthcare Revenue Cycle</a></li>
                        <li><a itemprop="relatedLink" target="_blank" rel="noreferrer" href="/content/docs/ServiceCards/NCG_IVV.pdf">Independent Verification &amp; Validation (IV &amp; V)</a></li>
                        <li><a itemprop="relatedLink" target="_blank" rel="noreferrer" href="/content/docs/ServiceCards/NCG_Project_Managment_Execution.pdf">Project Management <br />&amp; Execution</a></li>
                        <li><a itemprop="relatedLink" target="_blank" rel="noreferrer" href="/content/docs/ServiceCards/NCG_Project_Oversight.pdf"> Project Oversight</a></li>
                        <li><a itemprop="relatedLink" target="_blank" rel="noreferrer" href="/content/docs/ServiceCards/NCG_Risk_Management.pdf">Risk Management</a></li>
                      </ul>

                      <a itemprop="relatedLink" id="adobe" href="https://get.adobe.com/reader/" target="_blank" rel="noreferrer"><h5><AdobeFileLogo /> Download Adobe</h5></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default IntegrationMicrosite;