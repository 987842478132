import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Container } from "@material-ui/core";
import React from "react";
import LinkDefault from "components/layout/linkDefault.component";
import PageWrapper from "components/layout/pageWrapper.component";
import BreadcrumbMenu from "components/layout/breadcrumbMenu.component";
import BackToTopCustom from "components/layout/BackToTopCustom";
import BreadcrumbItem from "components/layout/breadcrumbItem.component";
import Banner from "components/layout/banner.component";
import classNames from "classnames";
import variables from "../../../style/variables.module.scss";
// IMAGES
import corporateInformationBanner from "../../../images/images/banners/corp.jpg";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingLeft: 25,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  // SIDEBAR
  sideBar: {
    backgroundColor: variables.ncgGray1,
    maxWidth: 300,
    minWidth: 300,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    display: "inline-block",
    position: "absolute",
    marginTop: 2,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 240,
      minWidth: 240,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: "100%",
      width: "100%",
    },
  },
  sideBarH5: {
    marginTop: 20,
    marginBottom: 10,
  },
  sideBarWrapper: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ced8e2",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      textAlign: "center",
    },
  },
  sideBarWrapperLast: {
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  sideBarList: {
    paddingLeft: 0,
    paddingRight: 20,
    marginTop: 0,
    width: "fit-content",
    [theme.breakpoints.down('xs')]: {
      textAlign: "center",
      paddingRight: 0,
    },
  },
  sideBarListItemNoBullets: {
    fontSize: "13px",
    textAlign: "left",
    paddingTop: 0,
    lineHeight: "1.875em",
    letterSpacing: ".08em",
    listStyleType: "none",
    [theme.breakpoints.down('xs')]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  // SECTIONS
  pageContent: {
    paddingBottom: 80,
  },
  introSection: {
    paddingTop: 80,
    paddingBottom: 40,  
    [theme.breakpoints.down('xs')]: {
      paddingTop: 460,
      paddingBottom: 30,
    },
  },
  pageSection: {
    paddingTop: 55,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.down('xs')]: {
      paddingTop: 30
    },
  },
  pageSectionStriped: {
    borderBottom: "1px solid rgba(221, 221, 221, 0.62)",
    '&:nth-of-type(odd)': {
      backgroundColor: '#f7f9fc',
    },
  },
  pageSectionWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  subHeading: {
    fontSize: 21,
    color: variables.ncgBlue1
  },
  // CODES TABLE
  codesList: {
    padding: 0,
    listStyleType: "none",
    width: "100%",
  },
  codesListItem: {
    fontFamily: "Helvetica Neue, Helvetica, sans-serif",
    color: variables.textPrimary,
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    padding: "15px 10px",
    fontSize: 12,
    lineHeight: "normal",
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(235, 241, 247, 0.82)',
      borderBottom: "1px solid rgba(221, 221, 221, 0.62)",
    },
    '&:last-of-type': {
      borderBottom: "1px solid rgba(221, 221, 221, 0.62)",
    },
  },
  bold: {
    fontWeight: 700,
    display: "inline-block",
    paddingRight: 10,
  },
  listItemWrapper: {
    display: "inline-block",
  }
}));

const CorporateInformationPage = () => {
  const classes = useStyles();
  return (
    <>
      <BackToTopCustom />
      <Banner backgroundImage={corporateInformationBanner}>
        Corporate Information</Banner>
      <PageWrapper >
        <BreadcrumbMenu>
          <BreadcrumbItem href="/about" variant="link">About NCG</BreadcrumbItem>
          <BreadcrumbItem href="/about-ncg/corporate-background" variant="active">Corporate Information</BreadcrumbItem>
        </BreadcrumbMenu>
        <Container maxWidth="lg" className={classes.mainContent}>
          <Grid item lg={3} className={classes.sideBar}>
            <div className={classNames(classes.sideBarWrapper)}>
              <Typography variant="h5" className={classes.sideBarH5}>ACCESSIBILITY STATEMENT</Typography>
              <Typography variant="body2">
                Northcross Group (NCG) is committed to making its websites accessible to all NCG customers and staff in accordance with provisions of Section 508 of the Rehabilitation Act Amendments of 1998.
              </Typography>
            </div>
            <div className={classNames(classes.sideBarWrapper, classes.sideBarWrapperLast)}>
              <Typography variant="h5" className={classes.sideBarH5}>CORPORATE CAPABILITIES</Typography>
              <ul className={classes.sideBarList}>
                <li className={classes.sideBarListItemNoBullets}>
                  <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/corporateinfo/NCG_Services_2019.pdf">Corporate Capabilities Statement</LinkDefault>
                </li>
                <li className={classes.sideBarListItemNoBullets}>
                  <LinkDefault variant="downloadMini" target="_blank" href="../../../../content/docs/corporateinfo/NCGBrochure.pdf">NCG Brochure</LinkDefault>
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item className={classes.pageContent}>
            <Grid item xs={12} sm={7} md={7} lg={7} className={classes.introSection}>
              <Typography variant="h2" >
                Overview
              </Typography>
              <Typography variant="body1">
                The Northcross Group (NCG) is a New England-based firm providing management and technology services. The NCG Team is a small team comprised of professionals that bring specific and demonstrated expertise to every engagement.
              </Typography>
              <Typography variant="body1">
                NCG was founded in 2006 and is incorporated in the state of Maine.  NCG principals have decades of experience in management and technology consulting. Our Team has had many prior opportunities to work together and provide value to our clients.
              </Typography>
              <Typography variant="body1">
                Continuous improvement of our processes and capabilities are central to our approach to business. We believe in managed growth and always maintaining the principles and philosophy of delivering business value with technology.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={11} md={9} lg={7} className={classes.pageSection}>
              <Typography variant="h2" >
                NCG Provides the Following:
              </Typography>
              <Typography variant="h4" >
                Test
              </Typography>
              <div className={classes.codesTableWrapper}>
                <Typography variant="h3" className={classes.subHeading} >
                  NAICS Codes
                </Typography>
                <ul className={classes.codesList}>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      518210
                    </div>
                    <div className={classes.listItemWrapper}>
                      DATA PROCESSING, HOSTING, AND RELATED SERVICES</div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      541219
                    </div>
                    <div className={classes.listItemWrapper}>
                      OTHER ACCOUNTING SERVICES</div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      541330
                    </div>
                    <div className={classes.listItemWrapper}>
                      ENGINEERING SERVICES</div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      541511
                    </div>
                    <div className={classes.listItemWrapper}>
                      CUSTOM COMPUTER PROGRAMMING SERVICES</div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      541512
                    </div>
                    <div className={classes.listItemWrapper}>
                      COMPUTER SYSTEMS DESIGN SERVICES</div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      541513
                    </div>
                    <div className={classes.listItemWrapper}>
                      COMPUTER FACILITIES MANAGEMENT SERVICES</div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      541519
                    </div>
                    <div className={classes.listItemWrapper}>
                      OTHER COMPUTER RELATED SERVICES</div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      541611
                    </div>
                    <div className={classes.listItemWrapper}>
                      ADMINISTRATIVE MANAGEMENT AND GENERAL MANAGEMENT CONSULTING SERVICES</div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      541612
                    </div>
                    <div className={classes.listItemWrapper}>
                      HUMAN RESOURCES CONSULTING SERVICES</div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      541614
                    </div>
                    <div className={classes.listItemWrapper}>
                      PROCESS, PHYSICAL DISTRIBUTION, AND LOGISTICS CONSULTING SERVICES</div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      541618
                    </div>
                    <div className={classes.listItemWrapper}>
                      OTHER MANAGEMENT CONSULTING SERVICES</div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      541690
                    </div>
                    <div className={classes.listItemWrapper}>
                      OTHER SCIENTIFIC AND TECHNICAL CONSULTING SERVICES</div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      541715
                    </div>
                    <div className={classes.listItemWrapper}>
                      RESEARCH AND DEVELOPMENT IN THE PHYSICAL, ENGINEERING, AND LIFE SCIENCES</div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      541990
                    </div>
                    <div className={classes.listItemWrapper}>
                      ALL OTHER PROFESSIONAL, SCIENTIFIC, AND TECHNICAL SERVICES</div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      561110
                    </div>
                    <div className={classes.listItemWrapper}>
                      OFFICE ADMINISTRATIVE SERVICES</div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      561499
                    </div>
                    <div className={classes.listItemWrapper}>
                      ALL OTHER BUSINESS SUPPORT SERVICES</div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      611430
                    </div>
                    <div className={classes.listItemWrapper}>
                      PROFESSIONAL AND MANAGEMENT DEVELOPMENT TRAINING</div>
                  </li>
                </ul>
              </div>

              <div className={classes.codesTableWrapper}>
                <Typography variant="h3" className={classes.subHeading} >
                  PSC Codes
                </Typography>
                <ul className={classes.codesList}>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      D311
                    </div>
                    <div className={classes.listItemWrapper}>
                      IT AND TELECOM- DATA CONVERSION</div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      D302
                    </div>
                    <div className={classes.listItemWrapper}>
                      IT AND TELECOM- SYSTEMS DEVELOPMENT</div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      D310
                    </div>
                    <div className={classes.listItemWrapper}>
                    IT AND TELECOM- CYBERSECURITY AND DATA BACKUP
                    </div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      D301
                    </div>
                    <div className={classes.listItemWrapper}>
                    IT AND TELECOM- FACILITY OPERATION AND MAINTENANCE
                    </div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      D307
                    </div>
                    <div className={classes.listItemWrapper}>
                    IT AND TELECOM- IT STRATEGY AND ARCHITECTURE
                    </div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      R415
                    </div>
                    <div className={classes.listItemWrapper}>
                    SUPPORT- PROFESSIONAL: TECHNOLOGY SHARING/UTILIZATION
                    </div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      7010
                    </div>
                    <div className={classes.listItemWrapper}>
                    ADPE SYSTEM CONFIGURATION
                    </div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      D305
                    </div>
                    <div className={classes.listItemWrapper}>
                    IT AND TELECOM- TELEPROCESSING, TIMESHARE, AND CLOUD COMPUTING
                    </div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      R429
                    </div>
                    <div className={classes.listItemWrapper}>
                      SUPPORT- PROFESSIONAL: EMERGENCY RESPONSE, DISASTER PLANNING, AND PREPAREDNESS SUPPORT
                    </div>
                  </li>
                  <li className={classes.codesListItem}>
                    <div className={classes.bold}>
                      D314
                    </div>
                    <div className={classes.listItemWrapper}>
                    IT AND TELECOM- SYSTEM ACQUISITION SUPPORT
                    </div>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Container>
      </PageWrapper>
    </>
  )
}

export default CorporateInformationPage;