import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import MicrositeCards from "./mainServiceCards.component";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: 20
  },
  titleMargin: {
    marginTop: 20,
    display: "flex",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center"
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 400,
      paddingLeft: 50 - 24,
      marginLeft: "auto",
      marginRight: "auto"
    },
  }
}));


const Spotlight = (props) => {
  const { variant } = props;
  const classes = useStyles();

  if (variant === "featured") {
    return (
      <>
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs={12} className={classes.titleMargin}>
          <Typography variant="h2">
            Explore Our Services & Partnerships
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <MicrositeCards
            variant="featured"
          />
        </Grid>
      </Grid>
    </>
    );
  }

  return (
    <>
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs={12} className={classes.titleMargin}>
          <Typography variant="h2">
            Service Areas & Tools
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <MicrositeCards />
        </Grid>
      </Grid>
    </>
  )
}
export default Spotlight;