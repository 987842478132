import { makeStyles } from "@material-ui/core/styles";
import grc from "../../images/images/index/featured/GRC.jpg";
import devOps from "../../images/images/index/featured/devops.jpg"
import privacyPrograms from "../../images/images/index/featured/privacyPrograms.jpg";
import rms from "../../images/images/index/featured/riskManagement.jpg";
import techCyberEd from "../../images/images/index/featured/techCyberSkills.jpg";
import imp from "../../images/images/index/featured/incidentManagement2.jpg";
import sms from "../../images/images/index/featured/sms.jpg";
import bankingFinancial from "../../images/images/index/featured/bankingFinancial.jpg";
import cybersecurity from "../../images/images/index/featured/cybersecurity.jpg";
import integration from "../../images/images/index/featured/integration.jpg";
import mergersAcquisitions from "../../images/images/index/featured/mergersAcquisitions.jpg";
import vendorManagement from "../../images/images/index/featured/vendorManagement.jpg";
import orchestration from "../../images/images/index/featured/orchestration.jpg";
import programOffice from "../../images/images/index/featured/programOffice.jpg";
import systemBlueprinting from "../../images/images/index/featured/systemBlueprinting.jpg";
import complianceOversight from "../../images/images/index/featured/complianceOversight.jpg";
import ServiceCard from "./serviceCard.component";
import ButtonCustom from "./buttonCustom.component";
import { Grid, Link, Typography } from "@material-ui/core";
import classNames from "classnames";
import nmuLogo from "../../images/images/nmu-partnership/upci-logo.png";
import { useCallback } from "react";

const complianceOversightPDF = "../../../../content/docs/ServiceCards/NCG_Compliance_Governance.pdf";
const systemBlueprintingPDF = "../../../../content/docs/ServiceCards/NCG_Core_Blueprinting.pdf";
const programOfficePDF = "../../../../content/docs/ServiceCards/NCG_Program_Office_Services.pdf";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center"      
    },
  },
  cardContainer: {
    [theme.breakpoints.down('xs')]: {
      margin: "auto",
      padding: `0 ${50 - 24}px`,
      maxWidth: 400,
    }
  },
  nmuLogo: {
    width: "120px",
    paddingTop: 16,
    paddingBottom: 16
  },
  partnershipContainer:{
    boxShadow: "0px 2px 4px 1px rgba(0,0,0,0.16)",
    borderRadius: 5,
    height: "420px",
    margin: "0px",
    textAlign: "center",
    background: "rgba(0,0,0,0.03)",
    borderTop: "5px solid #095339",
    "&:hover": {
      boxShadow:  "0px 2px 4px 1px rgba(9, 83, 57, 0.36)",
    },
  },
  noMargin: {
    marginTop: -20,
    marginBottom: -30
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center"
  },
  cardButton: {
    height: "40px",
    position: "relative",
    bottom: 13
  },
  paddingNMU:{
    paddingLeft: 35,
    paddingRight: 35,
  }

}))



const MainServiceCards = (props) => {
  const { variant } = props;
  const classes = useStyles();

  const handleDownload = useCallback(async (docPath) => {
    window.open(docPath, '_blank');
  }, []);

  if (variant === "featured") {
    return (
      <div className={classes.root}>
      <Grid container spacing={3} className={classes.cardContainer}>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Governance, Risk, & Compliance"
            description="Building, enhancing, and operating Governance, Risk, and Compliance Programs"
            image={grc}
            link="/grc"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="DevOps Implementation & Enhancements"
            description="Tooling and automation to better manage and secure high-velocity development update chains and operations"
            image={devOps}
            link="/devops"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Cybersecurity Maturity Model Certification"
            description="DoD CMMC Certification preparation and program implementation"
            image={cybersecurity}
            link="/cmmc"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Privacy Programs & Assessment Services"
            description="Program and Privacy Impact Assessment development, operations, and improvements"
            image={privacyPrograms}
            link="/privacy-services"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Risk Management & Compliance"
            description="Design, implementation, and operations of structured frameworks to assess, analyze, and manage risks across the enterprise"
            image={rms}
            link="/risk-management"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Tech & Cyber Skills & Education"
            description="NCG work with k-12, college, students and educators"
            image={techCyberEd}
            link="/education"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Incident Management & Recovery"
            description="Incident management capability design, implementation, operations, and tooling"
            image={imp}
            link="/incident-management"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Aviation Safety Management Systems"
            description="Design, development, implementation, and operations of FAA compliant SMS capabilities"
            image={sms}
            link="/sms"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Link
            color="inherit"
            href="/nmu-partnership"
          >
            <Grid container
              className={classes.partnershipContainer}
            >
              <Grid item xs={12}>
                <img src={nmuLogo} className={classes.nmuLogo} alt="partnershipLogo" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" className={classes.noMargin}>
                Northcross Group
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.noMargin}>
                <Typography variant="h4">
                  +
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.paddingNMU}>
                <Typography variant="h4">
                  Upper Peninsula Cybersecurity Institute at NMU
                </Typography>
              </Grid>
              <Grid item xs={12}
                className={classNames(classes.center, classes.cardButton)}
              >
                <ButtonCustom
                  href="/nmu-partnership"
                  typeOf="smallButtonWhite"
                >
                  Learn More
                </ButtonCustom>
              </Grid>
            </Grid>
          </Link>
        </Grid>
      </Grid>
    </div>
    );
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.cardContainer}>
      <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Governance, Risk, & Compliance"
            description="Building, enhancing, and operating Governance, Risk, and Compliance Programs"
            image={grc}
            link="/grc"
            titleNarrow
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="DevOps Implementation & Enhancements"
            description="Tooling and automation to better manage and secure high-velocity development update chains and operations"
            image={devOps}
            link="/devops"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Cybersecurity Maturity Model Certification"
            description="DoD CMMC Certification preparation and program implementation"
            image={cybersecurity}
            link="/cmmc"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Privacy Programs & Assessment Services"
            description="Program and Privacy Impact Assessment development, operations, and improvements"
            image={privacyPrograms}
            link="/privacy-services"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Risk Management & Compliance"
            description="Design, implementation, and operations of structured frameworks to assess, analyze, and manage risks across the enterprise"
            image={rms}
            link="/risk-management"
            titleNarrow
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Tech & Cyber Skills & Education"
            description="NCG work with k-12, college, students and educators"
            image={techCyberEd}
            link="/education"
            titleNarrow
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Incident Management & Recovery"
            description="Incident management capability design, implementation, operations, and tooling"
            image={imp}
            link="/incident-management"
            titleMedium
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Aviation Safety Management Systems"
            description="Design, development, implementation, and operations of FAA compliant SMS capabilities"
            image={sms}
            link="/sms"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Banking & Financial"
            description="System integration, program management, remediation management, and compliance functions in the financial services space"
            image={bankingFinancial}
            link="/banking-financial"
            titleNarrow
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Cybersecurity"
            description="Program and capabilities assessments, builds, and operations to standards and compliance requirements"
            image={cybersecurity}
            link="/cybersecurity"
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Integration Services"
            description="Program management and technical delivery for systems, data, and process integration"
            image={integration}
            link="/integration"
            titleNarrow
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Mergers & Acquisitions"
            description="Program management, technical delivery, and interim controls supporting M&A activities across the acquisition life cycle"
            image={mergersAcquisitions}
            link="/merger-acquisition"
            titleNarrow
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Vendor Management"
            description="Third-party and Supply Chain program development, implementation, and operations"
            image={vendorManagement}
            link="/vendor-management"
            titleNarrow
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Orchestration Platform"
            description="Governance, Risk, and Compliance SaaS systems to support growing organizations"
            image={orchestration}
            link="/orchestration-platform"
            titleNarrow 
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Program Office Support"
            description="Management and operations of enterprise program functions for compliance and organizational initiative functions"
            image={programOffice}
            onClick={() => handleDownload(programOfficePDF)}
            titleMedium
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="System Enterprise & Blueprinting"
            description="Streamline how your manual and automated systems work together"
            image={systemBlueprinting}
            onClick={() => handleDownload(systemBlueprintingPDF)}
            titleNarrow
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ServiceCard
            title="Compliance & Oversight"
            description="Design, implementation, and operations of structured frameworks to manage compliance across the enterprise"
            image={complianceOversight}
            onClick={() => handleDownload(complianceOversightPDF)}
            titleNarrow
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default MainServiceCards;
